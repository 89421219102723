
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import {
  FormDefinition,
  formInputRequired,
  formIsValidURL,
  formIsValidEmail
} from '../plugins/form-rules';

import { Company } from '../companyDashboardAPI/Company';
import { SinnoAppSettings } from '../companyDashboardAPI/SinnoAppSettings';
import { CompanyDashboardApi } from '../companyDashboardAPI/CompanyDashboardAPI';

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import MainCard from '@/components/MainCard.vue';
import ColorPreview from '../components/ColorPreview.vue';
import DialogDivider from '../components/DialogDivider.vue';
import { click2DateURLCheck } from '@/functions/c2d';

@Component({
  components: {
    MainCard: MainCard,
    LoadingSpinner: LoadingSpinner,
    ColorPreview: ColorPreview,
    DialogDivider: DialogDivider
  }
})
export default class CompanyView extends Vue {
  @Prop({ required: true })
  public readonly ApiClient!: CompanyDashboardApi;

  public loadingText = ''; //this.$vuetify.lang.t(`$vuetify.loadingPleaseWait`);
  public submittingForm = false;
  public errorMessage = '';

  public Company: Company = {
    companyId: -1,
    companyName: '',
    technicalContactEmail: '',
    technicalContactSms: ''
  };
  private OriginalCompany!: Company;

  public SinnoAppSettings: SinnoAppSettings = {
    brandInfos: {
      PrimaryColor: '',
      PrimaryContrastColor: '',
      SecondaryColor: '',
      SecondaryContrastColor: '',
      TextColor: '',
      TextHeaderColor: '',
      TextLinkColor: ''
    },
    click2DateURL: null,
    privacyPolicyURLDefault: '',
    GTC: '',
    cancellingInstructions: ''
  };
  private OriginalSinnoAppSettings!: SinnoAppSettings;

  public newCompanyLogoFile: File | null = null;
  public newCompanyLogoString = '';

  public companyLogoString: string | null = null;

  public companyDefaultLogoString: string | null = '';
  public companyLogoInfo: string = this.$vuetify.lang.t(
    `$vuetify.companyView_companyLogoDefaultSinnoLogo`
  );

  public form: FormDefinition = {
    valid: false,
    fields: {},
    rules: {
      uploadLogo: [this.checkUploadLogo()],
      companyName: [this.requiredRule()],
      technicalContactEmail: [this.isValidEmail(), this.requiredRule()],
      technicalContactSms: [this.requiredRule()],
      privacyPolicyURLDefault: [this.requiredRule(), this.isValidURL()],
      click2DateURL: [this.isValidURL()],
      GTC: [this.isValidURL()],
      cancellingInstructions: [this.isValidURL()]
    }
  };

  /**
   * Called on mounted
   *
   * Load all informations for the form
   */
  private async mounted() {
    this.loadingText = this.$vuetify.lang.t(
      `$vuetify.companyView_loadingCompanyData`
    );

    // Load company infos
    for await (const companyInfos of this.ApiClient.getCompany()) {
      this.Company = companyInfos;
      this.OriginalCompany = JSON.parse(JSON.stringify(companyInfos));
    }

    // Load company settings
    for await (const sinnoAppSettings of this.ApiClient.getSinnoAppSettings()) {
      // Set default brandinfos
      // ToDo: get the default brand infos from the api
      if (sinnoAppSettings.brandInfos == null) {
        sinnoAppSettings.brandInfos = {
          PrimaryColor: '#0062ae',
          PrimaryContrastColor: '#ffffff',
          SecondaryColor: '#e3e3e3',
          SecondaryContrastColor: '#0062ae',
          TextColor: '#2b2e34',
          TextHeaderColor: '#878787',
          TextLinkColor: '#0062ae'
        };
      }

      this.SinnoAppSettings = sinnoAppSettings;
      this.OriginalSinnoAppSettings = JSON.parse(
        JSON.stringify(sinnoAppSettings)
      );
    }

    // Load default company logo
    for await (const defaultLogo of this.ApiClient.getDefaultCompanyLogo()) {
      this.companyDefaultLogoString = defaultLogo;
    }

    // Load company logo
    for await (const companyLogo of this.ApiClient.getCompanyLogo()) {
      this.companyLogoString = companyLogo;
    }

    // Stop loading spinner and show form
    this.loadingText = '';
  }

  /**
   * Check upload branch logo
   */
  private checkUploadLogo() {
    return (file: any) =>
      !file ||
      file.size < 1_000_000 ||
      this.$vuetify.lang.t(`$vuetify.form_maxFileSize1MB`);
  }

  /**
   * Go back to branches view
   */
  public goBack(): void {
    this.$router.push({
      name: 'Home'
    });
  }

  /**
   * Get the current displayed logo
   */
  public getCurrentLogo(): string {
    const retVal =
      this.newCompanyLogoString == ''
        ? this.companyLogoString ?? this.companyDefaultLogoString
        : this.newCompanyLogoString;

    return retVal ?? '';
  }

  /**
   * Get the primary app color
   */
  public getPrimaryColor(): string {
    return this.SinnoAppSettings.brandInfos
      ? this.SinnoAppSettings.brandInfos.PrimaryColor
      : '';
  }

  /**
   * Check if e-mail is valid.
   * Use language texts.
   */
  private isValidEmail() {
    return formIsValidEmail(this.$vuetify.lang.t(`$vuetify.form_noValidEmail`));
  }

  /**
   * Check if url is valid.
   * Use language texts.
   */
  private isValidURL() {
    return formIsValidURL(this.$vuetify.lang.t(`$vuetify.form_noValidURL`));
  }

  /**
   * Set default Message for required field input
   */
  private requiredRule() {
    return formInputRequired(this.$vuetify.lang.t(`$vuetify.form_required`));
  }

  /**
   * Save the formdata
   */
  public async save(): Promise<void> {
    this.submittingForm = true;
    this.errorMessage = '';
    let errorMessage = '';

    // Check on Click2Date url
    this.SinnoAppSettings.click2DateURL = click2DateURLCheck(
      this.SinnoAppSettings.click2DateURL
    );

    // Update company if neccessary
    if (JSON.stringify(this.Company) != JSON.stringify(this.OriginalCompany)) {
      await this.ApiClient.putCompany(this.Company).catch((error) => {
        errorMessage +=
          this.$vuetify.lang.t(
            `$vuetify.companyView_errorMessageCantSaveCompany`
          ) + ' ';
        console.log('error in patchSinnoAppSettings');
        console.log(error);
      });
    }

    // Update SinnoAppSettings if necessary
    if (
      JSON.stringify(this.SinnoAppSettings) !=
      JSON.stringify(this.OriginalSinnoAppSettings)
    ) {
      await this.ApiClient.patchSinnoAppSettings(this.SinnoAppSettings).catch(
        (error) => {
          errorMessage +=
            this.$vuetify.lang.t(
              `$vuetify.companyView_errorMessageCantSaveSinnoAppSettings`
            ) + ' ';
          console.log('error in patchSinnoAppSettings');
          console.log(error);
        }
      );
    }

    // Upload new company logo
    if (this.newCompanyLogoFile) {
      await this.ApiClient.postSinnoAppSettingsCompanyLogo(
        this.newCompanyLogoFile
      ).catch((error) => {
        errorMessage +=
          this.$vuetify.lang.t(
            `$vuetify.companyView_errorMessageCantSaveNewCompanyLogo`
          ) + ' ';
        console.log('error in postSinnoAppSettingsCompanyLogo');
        console.log(error);
      });
    }

    this.errorMessage = errorMessage;
    this.submittingForm = false;

    if (errorMessage == '') {
      this.$router.push({
        name: 'Home'
      });
    }
  }

  /**
   * Change showing company logo
   */
  public selectedCompanyLogo(): void {
    this.newCompanyLogoString = !this.newCompanyLogoFile
      ? ''
      : URL.createObjectURL(this.newCompanyLogoFile);
  }

  /**
   * Remove company logo and show current company or default company logo
   */
  public removeCompanyLogo(): void {
    this.newCompanyLogoFile = null;
    this.selectedCompanyLogo();
  }
}
